@import 'choices.js/public/assets/styles/choices.css';

.choices {
  color: #030712 !important;
  border-style: solid !important;
  border-width: 1px !important;
  border-color: #030712 !important;
  border-radius: 0.5rem !important;
  width: 100% !important;
  margin-bottom: 0px !important;
}

.choices__input--cloned {
  color: #030712 !important;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  text-indent: 0.5rem !important;
  padding: 0.25rem !important;
  border-style: solid !important;
  border-radius: 0.5rem !important;
  width: 100% !important;
  height: 100% !important;
  margin-top: 0.5rem !important;
}

.choices__inner {
  padding: 0.5rem !important;
}

.choices__list--multiple .choices__item {
  background-color: #1D4ED8;
  border: none;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted,
.choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
  background-color: #dbeafe;
}